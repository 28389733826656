<template>
    <div
        class="AutocompleteHashtags-wrapper"
        :class="{'dropdown-open': isOpenDropdown}"
    >
        <textarea ref="story_description"
                  class="AutocompleteHashtags-textarea"
                  :name="name"
                  :rows="rows"
                  :placeholder="placeholder"
                  v-model="value"
                  :maxlength="maxlength"
                  @input="onInput"
                  @click="handleTextareaClick"
        ></textarea>
        <div class="AutocompleteHashtags-dropdown" v-if="isOpenDropdown && items.length">
            <div class="AutocompleteHashtags-item" v-for="(item, key) in items" :key="key" @click="handleClickAutocompleteItem(item.hashtag)">
                <profile-image
                    class="AutocompleteHashtags-item--avatar"
                    :src="item.avatar_url"
                    :username="item.username"
                />
                <div class="AutocompleteHashtags-item--username" v-html="hashtagHighlighting(item.username, item.hashtag)">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {HASHTAG_SYMBOLS, HASHTAG_BEFORE} from "@/configs/constants";
import ProfileImage from "@/components/app/notifications/ProfileImage";
import {mapActions} from "vuex";

export default {
    name: "AutocompleteHashtags",
    components: {
        ProfileImage,
    },
    model: {
        prop: 'model',
        event: 'input'
    },
    props: {
        rows: {
            type: String,
            default: '1'
        },
        placeholder: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        model: {
            type: [String, Number],
            default: ''
        },
        maxlength: {
            type: Number,
            default: 255,
        },
    },
    data: () => ({
        value: '',
        isOpenDropdown: false,
        hideDropdown: false,
        hashtag: false,
        items: [],
    }),
    watch: {
        model() {
            this.value = this.model;
        },
        value() {
            if (this.hideDropdown) {
                this.hideDropdown = false
                return
            }
            const hashtag = this.getHashtagCurrentWord()
            if (hashtag) {
                this.hashtag = hashtag
                this.openDropdown()
                return
            }
            this.closeDropdown()
        }
    },
    created() {
        this.value = this.model;
        // do not open dropdown at boot
        this.hideDropdown = true;
    },
    methods: {
        ...mapActions(['autocompleteUser']),
        getSelectedUsers() {
            return this.model
                .match(/@[0-9a-z-_]+/g)
                .map(u => u.substring(1))
        },
        onInput() {
            this.$emit('input', this.value)
        },
        openDropdown() {
            this.isOpenDropdown = true
            this.autocompleteUser({
                query: this.hashtag,
                except_users: this.getSelectedUsers(),
            }).then((res) => {
                this.items = res
            })
        },
        closeDropdown() {
            this.hashtag = ''
            this.isOpenDropdown = false
        },
        handleTextareaClick() {
            const hashtag = this.getHashtagCurrentWord()
            if (hashtag) {
                this.hashtag = hashtag
                this.openDropdown()
            } else {
                this.closeDropdown()
            }
        },
        isHashtagSymbol(symbol) {
            return !!HASHTAG_SYMBOLS.find(value => symbol && value === symbol.toLowerCase())
        },
        getHashtagCurrentWord() {
            const currentPosition = this.$refs.story_description.selectionStart ?
                this.$refs.story_description.selectionStart - 1 : 0
            if (!currentPosition) {
                return false
            }

            let allegedHashtag = false
            for (let i = currentPosition; i >= 0; i--) {
                if (!this.isHashtagSymbol(this.value[i])) {
                    return false
                } else if (this.value[i] === HASHTAG_BEFORE) {
                    return allegedHashtag
                } else {
                    allegedHashtag = this.value[i] + (allegedHashtag ? allegedHashtag : '')
                }
            }
            return false
        },
        handleClickAutocompleteItem(hashtag) {
            // Paste the hashtag in the text field instead of the entered part of the hashtag and move the cursor to the end of the new hashtag.
            const currentPosition = this.$refs.story_description.selectionStart
            const length = this.hashtag.length
            this.hashtag = hashtag
            const nextPosition = currentPosition - length + hashtag.length + 1
            // Hide dropdown when changing hashtag.
            this.hideDropdown = true
            this.value = this.value.substring(0, currentPosition - length) + hashtag + ' ' + this.value.substring(currentPosition,this.value.length)
            // ***
            this.changeCursorPosition(nextPosition)

            this.closeDropdown()
            this.$emit('input', this.value)
        },
        changeCursorPosition(position) {
            this.$refs.story_description.focus()
            setTimeout(() => this.$refs.story_description.setSelectionRange(position,position), 0)
        },
        hashtagHighlighting(username, hashtag) {
            const hashtagPosition = hashtag.toLowerCase().indexOf(this.hashtag.toLowerCase())
            if (hashtagPosition !== -1) {
                hashtag = hashtag.replace(this.hashtag, `<span>${this.hashtag}</span>`)
            }

            return username
        }
    }
}
</script>

<style scoped lang="scss">
.AutocompleteHashtags {
    &-textarea {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        color: #FFFFFF;
    }
    &-dropdown {
        border: solid 1px #707070;
        border-top: none;
        border-radius: 0 0 16px 16px;
        position: absolute;
        z-index: 2;
        width: calc(100% - 24px);
        display: flex;
        left: 12px;
        top: 70px;
        background-color: #1A1A1A;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 15px;
    }
    &-item {
        display: flex;
        height: 42px;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        padding-left: 15px;
        cursor: pointer;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0px;
        }

        &--avatar {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-size: cover;
            background-color: #FFFFFF;
            margin-right: 10px;
            overflow: hidden;
            display: flex;
        }
        &--username {
            font-size: 12px;
            line-height: 20px;
            padding-left: 5px;
            &::v-deep span {
                color: #FC881B;
            }
        }
    }
}
</style>
