<template>
    <div id="editor"
         style="{
            'width': width + 'px;'
         }"
         class="canvas_block"
         ref="canvas_block"
    >
        <v-stage
            ref="stageArea"
            :config="configStage"
            @mousemove="handleStageMouseMove"
            @mousedown="handleStageMouseDown"
            @mouseup="handleStageMouseUp"
            @tap="handleStageMouseDown"
            @touchstart="handleStageMouseDown"
            @touchend="handleStageMouseUp"
            @touchmove="handleStageMouseMove"
        >
            <v-layer ref="background">
                <v-group ref="backgroundGroup" class="backgroundGroup">
                    <v-image :config="configPhotoImage"/>
                </v-group>
            </v-layer>
            <v-layer ref="layer">
                <v-group ref="imageGroup">
                    <v-image v-for="image in Object.values(images)" :key="image.id" :config="image"/>
                    <v-line v-for="line in Object.values(lines)" :key="line.id" :config="line"/>
                </v-group>
                <v-transformer :config="configTransformer" ref="transformer"/>
            </v-layer>
        </v-stage>
    </div>
</template>

<script>
import {DEFAULT_STICKER_WIDTH} from "@/configs/constants"

export default {
    name: "EditorImage",
    props: {
        imageProps: Array,
        width: Number,
        height: Number,
        isDrawing: {
            type: Boolean,
            default: false,
        },
        photoImage: HTMLImageElement,
    },
    data() {
        return {
            configTransformer: {
                borderStroke: 'orange',
                anchorStroke: 'orange',
                anchorCornerRadius: 20,
                anchorSize: 15,
            },
            configPhotoImage: {
                image: this.photoImage,
                name: 'photoImage',
                x: 0,
                y: 0,
                width: this.width,
                height: this.height,
            },
            images: {},
            lines: {},
            openLine: false,
            selectedNode: null,
            imageGroup: null,
            transformer: null,
            stageArea: null,
            layer: null,
            paintBrush: false,
            lastPoint: false,
            selectedImageName: false,
            selectedLineName: false,
            lineIndex: 0,
            removeTransformerCount: 0,
        }
    },
    watch: {
        imageProps() {
            this.registerImages()
        }
    },
    computed: {
        configStage() {
            return {
                width: this.width,
                height: this.height,
            }
        },
        dataUrl() {
            this.transformer.detach()
            return this.stageArea.getStage().toDataURL({
                pixelRatio: window.devicePixelRatio
            })
        },
    },
    mounted() {
        this.transformer = this.$refs.transformer.getStage()
        this.imageGroup = this.$refs.imageGroup.getStage()
        this.stageArea = this.$refs.stageArea.getStage()
        this.layer = this.$refs.layer.getNode()
        this.$refs.backgroundGroup.getStage().getLayer().moveToBottom()
        this.registerImages()
    },
    methods: {
        handleStageMouseUp() {
            this.paintBrush = false
            this.openLine = false
        },
        handleStageMouseDown(e) {
            if (this.isDrawing) {
                this.paintBrush = true
            }
            if (e.target === e.target.getStage()) {
                this.selectedImageName = ""
                this.selectedLineName = ""
                this.selectedNode = null
                this.updateTransformer()
                return
            }
            // clicked on transformer - do nothing
            const clickedOnTransformer =
                e.target.getParent().className === "Transformer"
            if (clickedOnTransformer) {
                return
            }
            // find clicked rect by its id
            const name = e.target.name()
            const image = Object.values(this.images).find(r => r.name === name)
            if (image) {
                this.selectedImageName = name
                this.selectedNode = this.imageGroup.find(
                    ".".concat(this.selectedImageName)
                )[0]

                this.selectedNode.moveToTop()
            }
            const line = Object.values(this.lines).find(r => r.name === name)
            if (line) {
                this.selectedLineName = name
                this.selectedNode = this.imageGroup.find(
                    ".".concat(this.selectedLineName)
                )[0]

                this.selectedNode.moveToTop()
            }

            this.updateTransformer()
        },
        updateTransformer() {
            // here we need to manually attach or detach Transformer node
            const transformerNode = this.transformer
            if (this.cropMode) {
                transformerNode.detach()
            } else if (this.selectedNode === transformerNode.node()) {
                // do nothing if selected node is already attached
                return
            } else if (this.selectedNode) {
                // attach to another node
                this.removeTransformerCount = 0
                this.$emit('sticker-selected')
                this.selectedNode.moveToTop()
                transformerNode.attachTo(this.selectedNode)
            } else {
                // remove transformer
                this.removeTransformerCount++
                this.$emit('sticker-not-selected')
                if (this.removeTransformerCount === 2) {
                    this.removeTransformerCount = 0
                    this.$emit('closeStickers')
                }
                transformerNode.detach()
            }
            transformerNode.getLayer().batchDraw()
        },
        registerImages() {
            this.imageProps.forEach((value) => {
                const name = value.url + ':' + value.key
                if (!Object.prototype.hasOwnProperty.call(this.images, name)) {
                    let img = new Image()
                    img.src = value.url
                    img.onload = () => {
                        this.$set(this.images, name, {
                            id: value.key,
                            image: img,
                            draggable: true,
                            name: name,
                            x: Math.round((this.width - DEFAULT_STICKER_WIDTH) / 2),
                            y: Math.round((this.height - DEFAULT_STICKER_WIDTH) / 2),
                            width: 100,
                            height: 100,
                        })
                    }
                }
            })
        },
        removeSelectedImage() {
            let newImageProps = this.imageProps.filter(
                (value) => {
                    const [selectedUrl, selectedIndex] = this.selectedImageName.split(':')
                    return (value.url !== selectedUrl) && (value.key !== parseInt(selectedIndex))
                }
            )

            this.$emit("update:image-props", newImageProps)

            this.$delete(this.images, this.selectedImageName)
            this.$delete(this.lines, this.selectedLineName)
            this.selectedNode = null
            this.updateTransformer()
        },
        handleStageMouseMove(e) {
            if (this.paintBrush) {
                this.handleBrushDrawing(e)
            }

            if (!this.selectedNode) {
                if (e.evt.cancelable) {
                    e.evt.preventDefault();
                }
                e.evt.stopPropagation();
            }
        },
        handleBrushDrawing(e) {
            const index = this.lineIndex
            let x =  e.evt.layerX
            let y =  e.evt.layerY

            if (typeof e.evt.targetTouches !== 'undefined') {
                x = e.evt.targetTouches[0].clientX
                y = e.evt.targetTouches[0].clientY - this.$el.offsetTop
            }

            if (this.openLine === false) {
                const name = 'line:' + index
                this.$set(this.lines, name, {
                    id: name,
                    name: name,
                    points: [ x, y ],
                    stroke: 'orange',
                    lineJoin: 'round',
                    lineCap: 'round',
                    strokeWidth: 16,
                    draggable: true,
                })
                this.openLine = index
                this.lineIndex++
            } else {
                const line = this.lines['line:' + (index - 1)]
                line.points.push(x, y)
            }

            this.layer.batchDraw()
        },
    },

}
</script>
<style lang="scss" scoped>
</style>
