<template>
    <camera-layout class="ResultScreen" @touchmove="touchMove" @mouseup="mouseUp" @scroll.prevent @scroll.stop @wheel.prevent @mousedown="mouseDown">
        <div class="loadingApp" v-if="!isPhoto && !isVideo">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
        <div class="photo-container d-flex align-center" v-if="isPhoto">
            <div class="loadingApp">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>
            <img :width="width" :height="height" ref="myImage" alt="myImageSrc" :src="imageUrl"/>
        </div>
        <div class="video-container d-flex align-center" v-if="isVideo">
            <div class="loadingApp">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>
            <video ref="video" v-if="isVideo" autoplay class="video"
                   :class="{'mirrored': isFrontCamera}"
                   playsinline
                   :width="width" :height="height"
                   :muted="isMuted"
                   :src="videoDataUrl"
                   loop />
        </div>

        <div class="canvas_wrapper">
            <canvas ref="canvas" class="canvas_block" style="display: none" :width="width" :height="height"/>
            <editor-image
                ref="editor"
                :image-props="imageProps"
                :is-drawing="isDrawing"
                :width="width"
                :height="height"
                :photoImage="$refs.myImage"
                @sticker-selected="handleStickerSelected"
                @sticker-not-selected="handleStickerNotSelected"
                @closeStickers="handleCloseStickers"
                @update:image-props="handleUpdateImageProps"
                @update:stop-drawing="handleStopDrawing"
            />
            <div class="controlButtons-wrapper controlButtons-wrapper--exit">
                <close-button @click="$emit('close')"/>
            </div>
            <div class="controlButtons-wrapper controlButtons-wrapper--edit">
                <view-result-control-block
                    :is-drawing="isDrawing"
                    :can-delete="stickerSelected"
                    :is-stickers="isStickers"
                    :is-video="isVideo"
                    :is-muted="isMuted"
                    @selectSticker="handleSelectSticker"
                    @deleteSticker="handleDeleteSticker"
                    @closeStickers="handleCloseStickers"
                    @toggleStickers="handleToggleStickers"
                    @openStickers="handleOpenStickers"
                    @clickMute="toggleMute"
                    @toggleDrawing="handleToggleDrawing"
                />
            </div>
            <div class="controlButtons-wrapper controlButtons-wrapper--send">
                <view-result-send-button @click="send"/>
            </div>
        </div>
    </camera-layout>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import CameraLayout from "@/layouts/app/postingLayout/CameraLayout"
import CloseButton from "@/components/app/button/CloseButton"
import ViewResultControlBlock from "@/components/app/posting/ViewResultControlBlock"
import ViewResultSendButton from "@/components/app/posting/ViewResultSendButton"
import EditorImage from "@/components/app/posting/EditorImage"
import {isSafari} from "@/helpers/functions";
import {VIDEO_WEBM_MIMETYPE} from "@/configs/constants";

export default {
    name: "ViewResult",
    emits: ['close'],
    data: () => ({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
        canvas: '',
        canvasDrawing: '',
        isDrawing: false,
        indexStoryItem: 0,
        video: false,
        mouseDraw: false,
        photo: false,
        loadImage: false,
        stickerSelected: false,
        imageProps: [],
        dataUrl: '',
        isStickers: false,
        isSafari: false,
        stickerIndex: 0,
        videoMimeType: VIDEO_WEBM_MIMETYPE,
    }),
    components: {
        CameraLayout,
        CloseButton,
        ViewResultControlBlock,
        ViewResultSendButton,
        EditorImage,
    },
    computed: {
        isPhoto() {
            return this.type === 'photo'
        },
        isVideo() {
            return this.type === 'video'
        },
        ...mapState({
            type: state => state.story.type,
            videoBlob: state => state.story.videoBlob,
            videoDataUrl: state => state.story.videoDataUrl,
            imageUrl: state => state.story.imageDataUrl,
            isMuted: state => state.story.isMuted,
        }),
        isFrontCamera() {
            return this.$core.storyCore.isFrontCamera
        }
    },
    methods: {
        send() {
            this.$store.commit('setOverlayDataUrl', this.$refs.editor.dataUrl)
            this.setStep(2)
            //send something
        },
        touchMove() {
            if (!this.isDrawing)
                return false;
        },
        mouseDown() {
            if (!this.isDrawing) {
                return false;
            }
            this.mouseDraw = true
        },
        mouseUp() {
            if (!this.isDrawing) {
                return false;
            }
            this.mouseDraw = false
        },
        handleSelectSticker($event, sticker) {
            this.imageProps.push({
                url: sticker,
                key: this.stickerIndex,
            })
            this.stickerIndex++
        },
        handleDeleteSticker() {
            this.$refs.editor.removeSelectedImage()
        },
        handleStickerSelected() {
            this.stickerSelected = true
        },
        handleStickerNotSelected() {
            this.stickerSelected = false
        },
        handleUpdateImageProps(imageProps) {
            this.imageProps = imageProps
        },
        handleStopDrawing() {
            this.isDrawing = false
        },
        handleToggleDrawing() {
            this.isDrawing = !this.isDrawing
        },
        handleCloseStickers() {
            this.isStickers = false
        },
        handleOpenStickers() {
            this.isStickers = true
        },
        handleToggleStickers() {
            this.isStickers = !this.isStickers
        },
        handleDontRefreshPage(event) {
            if (this.isDrawing) {
                event.returnValue = 1
            }
        },
        ...mapMutations(['hideResultScreen', 'setStep', 'toggleMute', 'setFileResolution'])
    },
    mounted() {
        this.isSafari = isSafari()
        this.videoMimeType = this.$core.storyCore.getMimeType()
        this.canvas = this.$refs.canvas
        this.canvasDrawing = this.$refs.canvasDrawing
        this.photo = this.$refs.myImage || false;
        this.height = window.innerHeight;
        this.width = window.innerWidth;
        if (this.photo) {
            this.photo.src = this.imageUrl
            this.photo.onload = () => {
                this.height = screen.height
                this.width = screen.width
                this.setFileResolution({
                    width: this.width,
                    height: this.height,
                })
            }
        }

        if (this.isVideo) {
            const video = this.$refs.video
            video.src = this.videoDataUrl

            video.addEventListener("loadedmetadata", () => {
                this.height = screen.height
                this.width = screen.width
                this.setFileResolution({
                    width: this.width,
                    height: this.height,
                })
            })
        }
        window.addEventListener('beforeunload', this.handleDontRefreshPage);
    }
}
</script>

<style scoped lang="scss">
.mirrored {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.photo-container,
.video-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    height: 100vh;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-size: cover;
    & img,
    & video{
        object-fit: cover;
        position: absolute;
        top:0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
}
.konva-layer {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.canvas_wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    max-width: 100vw;
    width: auto;
    height: 100vh;
    pointer-events: all;
}

.controlButtons-wrapper {
    position: absolute;

    &--exit {
        top: 3em;
        left: 2em;
    }

    &--edit {
        top: 3em;
        right: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &--send {
        bottom: 7em;
        right: 2em;
    }
}
</style>
