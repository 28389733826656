<template>
    <div class="post-destination block">
        <v-row cols="12" align="center">
            <v-col cols="1">
                <slot name="prepend"></slot>
            </v-col>
            <v-col cols="9">
                <slot></slot>
            </v-col>
            <v-spacer/>
            <v-col cols="2" >
                <radio :value="value" @change="onChange(value)" @click="$emit('click', value)" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Radio from "@/components/app/form/Radio";

export default {
    name: "PostDestination",
    model: {
        prop: 'checked',
        event: 'change'
    },
    components: {
        Radio
    },
    props: {
        value: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            toggleValue: ''
        }
    },
    methods: {
        onChange(value) {
            this.$emit('change', value)
        }
    },
}
</script>

<style lang="scss">
.post-destination {
    border-radius: 16px;
    font-size: 15px;
    background-color: #1A1A1A;
    color: white;
    width: 100%;
    padding: 0 24px 0 1.6em;

}
</style>
