<template>
    <div id="posting-main-screen" class="d-flex flex-column">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "BeforePosting"
}
</script>

<style scoped>
#posting-main-screen {
    background: black;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 5;

}
</style>
