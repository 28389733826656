<template>
    <app-transition>
        <Dialog class="DirectMessage" @close="handleClose">
            <v-row class="fill-width">
                <v-col>
                    <div class="DirectMessage-title">{{ $t('dialogs.direct_message_title') }}</div>
                </v-col>
            </v-row>
            <v-row v-if="subscribe_price" class="fill-width">
                <v-col>
                    <v-btn outlined
                           block
                           height="50"
                           class="flex-column"
                           :disabled="!isShowSubscription"
                           @click="handleSubscribe"
                    >
                        <div><b>{{ $t('dialogs.subscribe') }}</b></div>
                        <div class="DirectMessage-price">
                            {{priceText(subscribe_price)}}
                        </div>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="fill-width">
                <v-col>
                    <v-btn outlined
                           block
                           height="50"
                           class="flex-column"
                           :disabled="!isShowDirectMessage"
                           @click="handleDirectMessage"
                    >
                        <div><b>{{ $t('dialogs.direct_message') }}</b></div>
                        <div class="DirectMessage-price">
                            {{priceText(direct_price)}}
                        </div>
                    </v-btn>
                </v-col>
            </v-row>
        </Dialog>
    </app-transition>
</template>


<script>
import Dialog from "@/components/app/form/Dialog";
import {CURRENCY_SYMBOL} from "@/configs/constants";

export default {
    name: "directMessageDialog",
    data: () => ({
        currency: CURRENCY_SYMBOL
    }),
    props: {
        subscribe_price: {
            type: [String, Number],
            default: 0
        },
        direct_price: {
            type: [String, Number],
            default: 0
        },
        paid_options: {
            type: Array,
            default: []
        },
    },
    methods: {
        handleClose() {
            this.$emit('close');
        },
        handleSubscribe() {
            this.$emit('subscribe');
        },
        handleDirectMessage() {
            this.$emit('direct-message');
        },
        priceText(price) {
            if (price) {
                return `${price}${this.currency} / ${this.$t('dialogs.month')}`
            }
            return `${this.$t('dialogs.free')}`
        }
    },
    computed: {
        isShowSubscription() {
            if (this.paid_options.length === 0){
                return true
            }
            if (this.paid_options.includes('subscription')) {
                return  true
            }
            return false;
        },
        isShowDirectMessage() {
            if (this.paid_options.length === 0){
                return true
            }
            if (this.paid_options.includes('direct_message')) {
                return  true
            }
            return false;
        },
    },
    components: {
        Dialog,
    }
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.DirectMessage {
    &-title {
        font-size: 20px;
        font-weight: bold;
        line-height: 19px;
        margin-bottom: 12px;
        display: flex;
        justify-content: center;
    }

    &-description {
        display: flex;
        margin-top: 18px;
        padding: 9px;
        background-color: #FFFFFF34;
        border: 1px solid #FFFFFF4D;
        border-radius: 15px;
        text-align: center;
        font-size: 15px;
        line-height: 21px;
        letter-spacing: 0.45px;
        color: #FFFFFF;
    }

    .v-btn {
        text-transform: initial;
    }

    &-price {
        font-size: 12px;
        line-height: 22px;
        letter-spacing: 0px;
    }
}

</style>
<style>
button.flex-column .v-btn__content {
    flex-direction: column !important;
}
</style>
